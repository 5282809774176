import React from "react";
import { GTM_INIT_CHECKOUT_UMOW_SIE } from "../../config/gtm_classes";
import Button from "../Button";

const ButtonDrw = ({ className = "", ...props }) => {
  const onClick = (e) => e.stopPropagation();

  return (
    <Button
      className={`btn-mydr-pp ${GTM_INIT_CHECKOUT_UMOW_SIE} ${className}`}
      // type="secondary"
      size="S"
      onClick={onClick}
      {...props}
    >
      Umów się
    </Button>
  );
};

export default ButtonDrw;
